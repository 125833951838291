
import { defineComponent, ref } from 'vue'
import global from '@/utils/global'
import pageHead from '../components/page-head.vue'
import { dispatchAdd } from '@/api/dispatch'
import { companyNameAuto, fileUpload, getUserCompany } from '@/api/base'
import { ElUploadRequestOptions } from 'element-plus/lib/components/upload/src/upload.type'

import { ElMessageBox } from 'element-plus'

export default defineComponent({
    name: 'DispatchDetail',
    components: {
        'page-head': pageHead
    },
    data () {
        return {
            dialogVisible: false,
            companyName: '',
            trueClientName: '',
            content: '',
            dropDownList: ['vue', 'vuex'],
            files: [] as {
          name : string
          url : string
          preview : string
          id : string
        }[],
            token: '',
            uploading: false,
            percentage: '' as string | number,
            progressStatus: '' as '' | 'success' | 'exception' | 'warning'
        }
    },
    created () {
        this.token = global.token
        this.getClientInfo()
    },
    methods: {

        async getClientInfo () {
            const res = await getUserCompany()
            this.trueClientName = res.clientInfo.name
        },
        async queryCompany (
            queryString : string,
            cd : (val : { value : string }[]) => void
        ) {
            if (queryString.length < 3) {
                return
            }
            const result = await companyNameAuto({
                search: queryString
            })
            cd(
                result.map(item => {
                    return { value: item }
                })
            )
        },
        async onDispatchAdd () {
            this.dialogVisible = false
            if (!this.trueClientName) {
                this.$message.error('请填写被实际劳动关系单位名称')
                return
            }
            if (!this.companyName) {
                this.$message.error('请填写被派遣单位名称')
                return
            }
            await dispatchAdd({
                name: this.companyName,
                true_client_name: this.trueClientName,
                content: this.content,
                fileIds: this.files.map(item => item.id)
            })
            this.companyName = ''
            this.content = ''
            this.files = []
            await this.$message.success('提交成功，正在为您返回上一页')
            this.$router.go(-1)
        },
        // 上传视频前预处理
        beforeVideo (file : File) : false | undefined {
            if (file.size / 1024 / 1024 > 20) {
                this.$message.error('请限制视频大小在20M以内')
                this.uploading = false
                return false
            }
        },
        // 上传视频
        async fileUpload (file : ElUploadRequestOptions) {
            const FormDatas = new FormData()
            FormDatas.append('iFile', file.file)
            const data = await fileUpload(FormDatas, {
                isLoading: false,
                onUploadProgress: (progressEvent : any) => {
                    const num =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0 // 百分比
                    file.onProgress(({
                        percent: num
                    } as unknown) as ProgressEvent) // 进度条
                    this.percentage = num
                    if (num === 100) {
                        file.onSuccess('arraybuffer')
                        this.progressStatus = 'success'
                    }
                }
            })
            this.percentage = 0
            this.files.push({
                name: data.fileInfo.file_name,
                url: data.fileInfo.external_url,
                preview: data.fileInfo.preview_url,
                id: data.fileInfo.file_id
            })
        },
        fileRemove (index : number) {
            this.files.splice(index, 1)
        }
    }
})
